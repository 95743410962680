<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      item-key="code+store_id"
      sort-by="user_name"
      mobile-breakpoint="10"
      class="table-cursor elevation-1 mt-3"
      :search="search"
      :loading="loading_status"
      loader-height="10"
      loading-text="Loading ..."
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>User access</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn class="mx-2" fab dark small color="#1c2e5a" to="Employees">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-toolbar>
        <v-text-field v-model="search" label="Search" class="mx-4" />
      </template>
      <template v-slot:[`item.picture`]="{ item }">
        <v-list-item-avatar>
          <v-img :src="getImagePhoto(item.account, item.picture)"></v-img>
        </v-list-item-avatar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.user_name }} <br />
        <small>
          {{ item.user_email }}
        </small>
      </template>
      <template v-slot:[`item.store_id`]="{ item }">
        {{ store_name(item.store_id) }}
      </template>
      <template v-slot:[`item.issue_date`]="{ item }">
        <small>
          {{ item.issue_date }}
        </small>
      </template>
    </v-data-table>

    <UserForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
    />
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createUser.js";
import UserForm from "../../components/UserForm.vue";
export default {
  components: { UserForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      table: "users",
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "user_name",
          dataType: "text",
        },
        {
          text: "Team name",
          align: "start",
          sortable: true,
          value: "store_id",
          dataType: "text",
        },
        {
          text: "Permit",
          align: "start",
          sortable: true,
          value: "user_access",
          dataType: "text",
        },
        {
          text: "Last login",
          align: "end",
          sortable: true,
          value: "issue_date",
        },
      ],
      storeLst: [],
    };
  },
  methods: {
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [],
      };
      this.loading_status = true;
      webserver("get_users", qry, (data) => {
        this.loading_status = false;
        this.get_stores();
        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/photos/" +
        account +
        "/employees/" +
        src +"?"+Date.now();
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    get_stores() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [],
      };
      webserver("get_table", qry, (data) => {
        this.storeLst = data;
        console.log(data);
      });
    },
    store_name(e) {
      const result = this.storeLst.find(({ code }) => code === e);
      if (result) {
        return result.name;
      } else {
        return e;
      }
      // console.log(result,this.storeLst);
      // return e;
    },
  },
};
</script>

<style lang="scss" scoped></style>
