var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"table-cursor elevation-1 mt-3",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"item-key":"code+store_id","sort-by":"user_name","mobile-breakpoint":"10","search":_vm.search,"loading":_vm.loading_status,"loader-height":"10","loading-text":"Loading ..."},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("User access")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"#1c2e5a","to":"Employees"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, item.picture)}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_name)+" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.user_email)+" ")])]}},{key:"item.store_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.store_name(item.store_id))+" ")]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(" "+_vm._s(item.issue_date)+" ")])]}}],null,true)}),_c('UserForm',{attrs:{"table":_vm.table,"item":_vm.item,"dialog":_vm.dialog,"newItem":_vm.newItem},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}}),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Loading ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }